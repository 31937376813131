import TYPE from "./Type";
import axios from "axios";
axios.defaults.withCredentials = true

export const closeAlert = () => dispatch => {
    dispatch({
        type: TYPE.CLOSE_ALERT
    })
}

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    const body = JSON.stringify({ email, password })
    try {
        const res = await axios.post("http://localhost:8000/dj-rest-auth/login/", body, config)
        localStorage.setItem("auth_token", res.data.access);
        dispatch({
            type: TYPE.LOGIN_SUCCESS,
            payload: res.data
        })
    } catch (err) {
        dispatch({
            type: TYPE.LOGIN_FAIL
        })
    }
}

export const verify = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ "token": localStorage.getItem('access') });
        try {
            await axios.post("http://localhost:8000/dj-rest-auth/token/verify/", body, config);
            dispatch({
                type: TYPE.VERIFY_SUCCESS
            });
        } catch (err) {
            dispatch({
                type: TYPE.VERIFY_FAIL
            });
            await dispatch(refresh());
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        });
    }
};

export const getUser = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('access')}`
            }
        };
        try {
            const res = await axios.get("http://localhost:8000/dj-rest-auth/user/", config);
            dispatch({
                type: TYPE.GET_USER_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: TYPE.GET_USER_FAIL
            });
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        });
    }
}

export const refresh = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        try {
            const res = await axios.post("http://localhost:8000/dj-rest-auth/token/refresh/", config);
            console.log(res.data);
            dispatch({
                type: TYPE.REFRESH_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            console.log(err);
            dispatch({
                type: TYPE.REFRESH_FAIL
            })
        }
    } else {
        dispatch({
            type: TYPE.GUEST_VIEW
        })
    }
}


export const logout = () => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    try {
        await axios.post("http://localhost:8000/dj-rest-auth/logout/", config);
        dispatch({
            type: TYPE.LOGOUT
        });
    } catch (err) {
        dispatch({
            type: TYPE.LOGOUT
        });
    }
}

export const googleLogin = (code) => async dispatch => {
    if (!localStorage.getItem('access')) {
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };
        const body = JSON.stringify({ code })
        console.log("Request Body:", body);

        try {
            const res = await axios.post("http://localhost:8000/dj-rest-auth/google/", body, config)
            console.log("Response Data:", res.data);
            localStorage.setItem("auth_token", res.data.access);

            dispatch({
                type: TYPE.LOGIN_SUCCESS,
                payload: res.data
            })
            
        } catch (err) {
            console.log("Error Response:", err.response ? err.response.data : err.message);

            dispatch({
                type: TYPE.LOGIN_FAIL
            })
        }
    } else {
        dispatch(verify());
        dispatch(getUser());
    }
}

export const fetchUserRole = () => async dispatch => {
    try {
        const token = localStorage.getItem("auth_token");
        if (token) {
            const response = await axios.get('http://localhost:8000/api/check_user_role/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const userRole = response.data.role;
            dispatch({
                type: 'CHECK_USER_ROLE_SUCCESS',
                payload: userRole
            });
        }
    } catch (error) {
        console.error('Failed to fetch user role:', error);
        dispatch({
            type: 'CHECK_USER_ROLE_FAIL'
        });
    }
};


export const addprescription = (email, first_name, last_name, password1, password2) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    const body = JSON.stringify({ email, first_name, last_name, password1, password2 });
    try {
        await axios.post("http://localhost:8000/dj-rest-auth/registration/", body, config);
        dispatch({
            type: TYPE.SIGNUP_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: TYPE.SIGNUP_FAIL
        });
    };
};
