import React from 'react';
import '../assets/css/App.css'
import '../assets/sass/style.scss';
import Main from '../MainIndex/Main';

function App() {

  return (
      <div className="horizontal-layout boxed loaded">
        <Main />
      </div>

  );
}

export default App;
