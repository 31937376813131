import React, { useState, useEffect } from 'react';
import Footer from './components/Footer';

const Patients = () => {
    const token = localStorage.getItem("auth_token");
    const [data, setData] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [medication, setMedication] = useState("");
    const [instructions, setInstructions] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [ModalVisible, setModalVisible] = useState(false);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/get_patients/`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
                Accept: "application/json",
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [token]);



    const handleAddPrescription = (patientId) => {
        setIsLoading(true);
        const prescriptionData = {
            patient: patientId,
            medication: medication,
            instructions: instructions
        };

        fetch(`${process.env.REACT_APP_API_URL}/prescriptions/`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`,
                Accept: "application/json",
            }),
            body: JSON.stringify(prescriptionData)
        })
            .then((res) => res.json())
            .then((data) => {
                console.log("Prescription added:", data);
                setMedication("");
                setInstructions("");
                setSelectedPatient(null);
                setModalVisible(false)
            })
            .catch((err) => {
                console.log(err.message);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    const openModal = (patientId) => {
        setSelectedPatient(patientId);
        setModalVisible(true);
    };


    const closeModal = () => {
        setModalVisible(false);
    };


    const handleMedicationChange = (e) => setMedication(e.target.value);
    const handleInstructionsChange = (e) => setInstructions(e.target.value);

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="bg-primary text-white">
                                            <th scope="col">Name</th>
                                            <th scope="col">ID</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Last Login</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((patient) => (
                                            <tr key={patient.id}>
                                                <td>
                                                    <strong>{patient.first_name} {patient.last_name}</strong>
                                                </td>
                                                <td>
                                                    <span className="text-muted">{patient.id}</span>
                                                </td>
                                                <td>
                                                    <span className="text-muted">{patient.email}</span>
                                                </td>
                                                <td>
                                                    <span className="text-muted text-nowrap">
                                                        {new Date(patient.last_login).toLocaleDateString()}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="actions">
                                                        <a href="edit-patient" className="btn btn-dark btn-sm btn-square rounded-pill">
                                                            <span className="btn-icon"><i className="fas fa-external-link-alt"></i></span>
                                                        </a>
                                                        <button
                                                            className="btn btn-primary btn-sm btn-square rounded-pill"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#prescription-modal"
                                                            onClick={() => openModal(patient.id)}
                                                        >
                                                            <span className="btn-icon"><i className="fas fa-edit"></i></span>
                                                        </button>
                                                        <button className="btn btn-error btn-sm btn-square rounded-pill">
                                                            <span className="btn-icon"><i className="fas fa-trash"></i></span>
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Add Prescription Modal */}
                    <div className={`custom-modal ${ModalVisible ? 'show' : ''}`} id="contact-modal" tabIndex="-1" aria-hidden="true">
                        <div className="custom-modal-dialog">
                            <div className="custom-modal-content">
                                <div className="custom-modal-header">
                                    <h5 className="custom-modal-title">Contact Us</h5>
                                    <button type="button" className="custom-close-button" onClick={closeModal}>×</button>
                                </div>
                                <div className="custom--modal-body">
                                        <div className="mb-3">
                                            <label htmlFor="medication" className="form-label">Medication</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="medication"
                                                value={medication}
                                                onChange={handleMedicationChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="instructions" className="form-label">Instructions</label>
                                            <textarea
                                                className="form-control"
                                                id="instructions"
                                                rows="3"
                                                value={instructions}
                                                onChange={handleInstructionsChange}
                                            ></textarea>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => handleAddPrescription(selectedPatient)}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? 'Adding...' : 'Add Prescription'}
                                            </button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="add-action-box">
                        <button className="btn btn-primary btn-lg btn-square rounded-pill" data-bs-toggle="modal" data-bs-target="#add-patient">
                            <span className="btn-icon icofont-plus"></span>
                        </button>
                    </div>



                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Patients;
