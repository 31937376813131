import React, { useState , useEffect } from "react";
import { DOCTOR_IMG1, DOCTOR_IMG2, DOCTOR_IMG3} from "../../assets/images/imagespath/imagepath"

function SlideShowContainer() {
    const [currentSlide, setCurrentSlide] = useState(0);
  
    const slides = [
      {
        image: DOCTOR_IMG1,
      },
      {
        image: DOCTOR_IMG2,
      },
      {
        image: DOCTOR_IMG3,
      }
    ];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 7000);
  
      return () => clearInterval(interval);
    }, [slides.length]);
  
    const goToPreviousSlide = () => {
      setCurrentSlide(
        (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
      );
    };
  
    const goToNextSlide = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };
  
    return (
      <div className="slideshow-container">
        <button className="arrow-button left" data-testid="Left" onClick={goToPreviousSlide}>
          &#8249;
        </button>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img src={slide.image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
        <button className="arrow-button right" data-testid="Right" onClick={goToNextSlide}>
          &#8250;
        </button>
      </div>
    );
  };
  
  export default SlideShowContainer;