import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Footer from "../../components/Footer";
import SlideShowContainer from "./SlideShowContainer";
import { DOCTOR_IMG4, DOCTOR_IMG5, IMG_2, IMG_3, IMG_4 } from "../../assets/images/imagespath/imagepath"


const Home = ({ isAuthenticated }) => {
    const [checkedrole, setCheckedrole] = useState("");
    const [role, setRole] = useState("");
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [roleModalVisible, setRoleModalVisible] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [contactFormData, setContactFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        service: ''
    });
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("auth_token");
    const API_BASE_URL = process.env.REACT_APP_API_URL;

    const opencontactModal = () => {
        setContactModalVisible(true);
    };

    const closecontactModal = () => {
        setContactModalVisible(false);
        setConfirmationModalVisible(false);
        setRoleModalVisible(false);
    };

    useEffect(() => {
        const fetchUserRole = async () => {
            const token = localStorage.getItem("auth_token");

            if (token) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/check_user_role/`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const userRole = data.role;
                        setCheckedrole(userRole);
                        console.log("Role", userRole);
                    } else {
                        console.error('Failed to check user role:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error checking user role:', error);
                }
            }
        };

        if (isAuthenticated) {
            fetchUserRole();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (checkedrole === 'new_user') {
            setRoleModalVisible(true);
        }
    }, [checkedrole]);


    const handleRoleSubmit = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/select_user_role/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ role: role }),
            });

            if (response.ok) {
                console.log("Role submission successful");
                setRoleModalVisible(false);
                window.location.reload();
            } else {
                const errorData = await response.json();
                console.error("Role submission failed:", errorData);
            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContactFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleContactSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/save_contact_us_data/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(contactFormData),
            });
            if (response.ok) {
                setContactFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    service: ''
                });
                setContactModalVisible(false);
                setConfirmationModalVisible(true);
            } else {
                const errorData = await response.json();
                console.error("Contact form submission failed:", errorData);
            }
        } catch (error) {
            console.error("Error:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const faqs = [
        {
            id: 1,
            question: "Are your physiotherapists licensed?",
            answer: "Yes, all our physiotherapists are licensed and have undergone rigorous training to ensure the highest standard of care."
        },
        {
            id: 2,
            question: "Do I have to show a doctor's reference for therapy?",
            answer: "While a doctor's reference is not mandatory, it can help our physiotherapists tailor the treatment plan more effectively to your specific needs."
        },
        {
            id: 3,
            question: "What should I wear for therapy?",
            answer: "We recommend wearing loose, comfortable clothing that allows for easy movement, such as athletic wear or casual clothes."
        },
        {
            id: 4,
            question: "How many sessions will I need?",
            answer: "The number of sessions required varies based on individual needs and the specific condition being treated. Our physiotherapist will provide a personalized treatment plan after the initial assessment."
        },
        {
            id: 5,
            question: "What should I expect in my first therapy session?",
            answer: "During your first session, the physiotherapist will conduct a thorough assessment of your condition, discuss your goals, and create a tailored treatment plan. You may also begin some initial exercises or treatments."
        },
        {
            id: 6,
            question: "Can I cancel/reschedule my session?",
            answer: "Yes, you can cancel or reschedule your session by contacting our office at least 24 hours in advance. This allows us to accommodate other patients in need."
        },
        {
            id: 7,
            question: "In case of injury, how soon should I see a physiotherapist?",
            answer: "It is advisable to see a physiotherapist as soon as possible after an injury to start the recovery process and prevent further complications."
        },
    ];


    const [openPanel, setOpenPanel] = useState(null);

    const togglePanel = (panelId) => {
        setOpenPanel((prevOpenPanel) => (prevOpenPanel === panelId ? null : panelId));
    };

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <SlideShowContainer />
                    <div className='services-section' id="care-services">
                        <div class="col-12 text-center">
                            <h4 class="mt-0 default_color">Packages curated for you</h4>
                        </div>
                        <p className="mx-auto" style={{ width: "700px", fontSize: "15px" }}>
                            Satayush offers two main types of services: Nursing Care and Physiotherapy Sessions.
                            Each service is tailored to meet the specific needs of our elder patients,
                            ensuring they receive the best possible care in the comfort of their own homes.
                        </p>
                        <div className='package-grid col-lg-12'>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-glasses"></i>
                                </div>
                                <div className='details'>
                                    <h3>Administering Injections</h3>
                                    <p>
                                        Our agency provides professional injection administration services at your convenience, ensuring safety and comfort for your loved ones.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-house-chimney-medical"></i>
                                </div>
                                <div className='details'>
                                    <h3>Wound Dressing</h3>
                                    <p>
                                        We understand that as people get older, they may face difficulties traveling to the doctor. Our service arranges for wound dressing at home, providing necessary care without the need for travel.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-mobile-screen-button"></i>
                                </div>
                                <div className='details'>
                                    <h3>Intravenous Line Setup</h3>
                                    <p>
                                        We offer professional intravenous (IV) line setup services, ensuring proper medication administration and monitoring for your loved ones at home.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-percent"></i>
                                </div>
                                <div className='details'>
                                    <h3>Ryles Tube Insertion and Removal</h3>
                                    <p>
                                        Our experienced medical team handles Ryles tube insertion and removal with care, ensuring comfort and safety for patients requiring nutritional support.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-user-doctor"></i>
                                </div>
                                <div className='details'>
                                    <h3>Paralysis Care</h3>
                                    <p>
                                        We provide dedicated care for individuals with paralysis, offering round-the-clock assistance and personalized attention to meet their unique needs.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-tooth"></i>
                                </div>
                                <div className='details'>
                                    <h3>Orthopedic Care</h3>
                                    <p>
                                        Our team of qualified orthopedic specialists offers comprehensive care, from diagnosis to treatment, ensuring optimal recovery and mobility for patients.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-ear-deaf"></i>
                                </div>
                                <div className='details'>
                                    <h3>Pulmonary & Tracheostomy Care</h3>
                                    <p>
                                        We provide specialized care for individuals with pulmonary and tracheostomy needs, ensuring proper management and support for respiratory health.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-house-chimney-medical"></i>
                                </div>
                                <div className='details'>
                                    <h3>Arranging Home Visits</h3>
                                    <p>
                                        We facilitate home visits by healthcare professionals, making it easier for your loved ones to receive medical attention without the need to travel.
                                    </p>
                                </div>
                            </div>
                            <div className="item">
                                <div className='wrapper'>
                                    <i class="fa-solid fa-ear-deaf"></i>
                                </div>
                                <div className='details'>
                                    <h3>Catheter Insertion, Change, and Removal</h3>
                                    <p>
                                        Our trained professionals provide catheter services, including insertion, change, and removal, ensuring hygiene and comfort for patients.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mb-5 Background-gradient" id="testimonials">
                        <div class="col-12 text-center">
                            <h4 class="mt-3 default_color Text-size">
                                <span class="Black-clr">Our</span> Testimonials
                            </h4>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="card bg-light">
                                <div class="card-header d-flex align-items-center">
                                    <img src={IMG_2} alt="Ravi Rao" class="testimonial-img" />
                                    <span>Ravi Rao</span>
                                </div>
                                <div class="card-body">
                                    Satayush has been a blessing for my family. The nurses are extremely professional and compassionate.
                                    My father has never been happier with the care he receives.
                                </div>
                                <div class="card-body">
                                    <i class="fa-solid fa-quote-left"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="card bg-light">
                                <div class="card-header d-flex align-items-center">
                                    <img src={IMG_3} alt="Shruti krishna" class="testimonial-img" />
                                    <span>Shruti krishna</span>
                                </div>
                                <div class="card-body">
                                    The nurses at Satayush are so kind and understanding. They treated my mother with the utmost respect and care.
                                </div>
                                <div class="card-body">
                                    <i class="fa-solid fa-quote-left"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="card bg-light">
                                <div class="card-header d-flex align-items-center">
                                    <img src={IMG_4} alt="  Meera prasad" class="testimonial-img" />
                                    <span>  Meera prasad</span>
                                </div>
                                <div class="card-body">
                                    The physiotherapy sessions have greatly improved my mobility. The convenience of having these services at
                                    home has been invaluable
                                </div>
                                <div class="card-body">
                                    <i class="fa-solid fa-quote-left"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section section-two" id="about-us">
                        <div className="product-section">
                            <div className="section-two-container">
                                <div className="section-two-text">
                                    <h1>  About ElderCare Solutions   </h1>
                                    <p className="section-two-para1">
                                        At ElderCare Solutions, we understand the challenges faced by Indian families living abroad.
                                        Our mission is to provide top-quality medical support and compassionate care for aging parents
                                        in India. With on-demand access to skilled nurses and physiotherapists, you can rest assured
                                        that your loved ones are in good hands.
                                    </p>
                                </div>
                                <div className="section-two-img">
                                    <img className="img-fluid" alt="" src={DOCTOR_IMG4} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 p-0" id="contact-us">
                        <div
                            className="card"
                            style={{
                                backgroundImage: `url(${DOCTOR_IMG5})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                padding: "35px",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.1)"
                            }}
                        >
                            <div className="card-body col-md-4 m-4 mb-4"
                                style={{
                                    borderRadius: "inherit",
                                    border: "1px black solid",
                                    backgroundColor: "#fff"
                                }}>
                                <form onSubmit={handleContactSubmit} style={{ margin: "30px" }}>
                                    <div>
                                        <h3
                                            style={{
                                                fontSize: "24px",
                                                padding: "15px",
                                                color: "#336cfb",
                                                textAlign: "center"
                                            }}>
                                            Book a Qualified Nurse or Caretaker with us today
                                        </h3>
                                    </div>
                                    <div className="form-group row mt-4">
                                        <div className="col-sm-12">
                                            <input
                                                type="text"
                                                name="name"
                                                value={contactFormData.name}
                                                onChange={handleContactChange}
                                                className="form-control"
                                                placeholder="Your Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mt-4">
                                        <div className="col-sm-12">
                                            <input
                                                type="email"
                                                name="email"
                                                value={contactFormData.email}
                                                onChange={handleContactChange}
                                                className="form-control"
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mt-4">
                                        <div className="col-sm-12">
                                            <input
                                                type="tel"
                                                name="mobile"
                                                className="form-control"
                                                value={contactFormData.mobile}
                                                onChange={handleContactChange}
                                                placeholder="Mobile Number"
                                            />
                                        </div>
                                    </div>
                           
                            <div className="form-group row mt-4">
                                <div className="col-sm-12">
                                    <select
                                        className="form-control"
                                        name="service"
                                        value={contactFormData.service}
                                        onChange={handleContactChange}
                                    >
                                        <option value="">Select a Service</option>
                                        <option value="Post Operative Care">Post Operative Care</option>
                                        <option value="Physiotherapy">Physiotherapy</option>
                                        <option value="Paralysis Care">Paralysis Care</option>
                                        <option value="Pulmanory & Tracheostemy Care">Pulmanory & Tracheostemy Care</option>
                                        <option value="Delivery Of Medicines">Delivery Of Medicines</option>
                                        <option value="24 Hours Bedside Care">24 Hours Bedside Care</option>
                                        <option value="Arranging Home Visits">Arranging Home Visits</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row justify-content-end mb-0 mt-4">

                                <div className="col-sm-9">
                                    <button type="submit" className="btn btn-warning" disabled={loading}>
                                        {loading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            <>
                                                <span className="btn-icon icofont-location-arrow me-2"></span>Submit
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row mb-5 pb-5 Background-gradient">
                <div className="col-12 text-center">
                    <h4 className="mt-5 default_color Text-size">
                        <span className="Black-clr">Frequently</span> Asked Questions
                    </h4>
                </div>
                <div className="col-md-10 mx-auto">
                    {faqs.map((faq) => (
                        <div className="panel panel-default" key={faq.id}>
                            <div className="panel-heading">
                                <h4 className="panel-title d-flex align-items-center">
                                    <a
                                        onClick={() => togglePanel(faq.id)}
                                        aria-expanded={openPanel === faq.id ? "true" : "false"}
                                        aria-controls={`collapse${faq.id}`}
                                        href="#collapse"
                                        className="flex-grow-1"
                                    >
                                        {faq.question}
                                    </a>
                                    <span className="ms-auto">
                                        {openPanel === faq.id ? (
                                            <i class="fas fa-chevron-up arrow-down default_color"></i>
                                        ) : (
                                            <i class="fas fa-chevron-down arrow-down"></i>
                                        )}
                                    </span>
                                </h4>
                            </div>
                            <div id={`collapse${faq.id}`} className={openPanel === faq.id ? "collapse in" : "collapse"}>
                                <div className="panel-body">
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
            <div class="add-action-box">
                <button class="btn btn-dark btn-lg btn-square rounded-pill" onClick={() => opencontactModal()}>
                    <span><i class="fas fa-phone"></i></span>
                </button>
            </div>
        </div>
            </div >
    {/* Role Modal */ }
    < div className = {`custom-modal ${roleModalVisible ? 'show' : ''}`} id = "modal-10" tabIndex = "-1">
        <div className="custom-modal-dialog">
            <div className="custom-modal-content">
                <div className="custom-modal-header">
                    <h5 className="custom-modal-title">Set Your Role</h5>
                    <button type="button" className="custom-close-button" onClick={closecontactModal}>×</button>
                </div>
                <div className="custom-modal-body">
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Role"
                            name="role"
                            value={role}
                            onChange={handleRoleChange}
                        />
                    </div>
                </div>
                <div className="custom-modal-footer">
                    <button type="button" className="btn btn-primary" onClick={handleRoleSubmit}>Save changes</button>
                </div>
            </div>
        </div>
            </div >

    {/* Contact Us Modal */ }
    < div className = {`custom-modal ${contactModalVisible ? 'show' : ''}`} id = "contact-modal" tabIndex = "-1" >
        <div className="custom-modal-dialog">
            <div className="custom-modal-content">
                <div className="custom-modal-header">
                    <h5 className="custom-modal-title">Contact Us</h5>
                    <button type="button" className="custom-close-button" onClick={closecontactModal}>×</button>
                </div>
                <div className="custom--modal-body">
                    <form onSubmit={handleContactSubmit}>
                        <div className="mb-3">
                            <label htmlFor="contact-name" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="contact-name"
                                name="name"
                                value={contactFormData.name}
                                onChange={handleContactChange}
                                placeholder="Your Name"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="contact-email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="contact-email"
                                name="email"
                                value={contactFormData.email}
                                onChange={handleContactChange}
                                placeholder="Your Email"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="contact-phone" className="form-label">Phone Number</label>
                            <input
                                type="tel"
                                className="form-control"
                                id="contact-phone"
                                name="mobile"
                                value={contactFormData.mobile}
                                onChange={handleContactChange}
                                placeholder="Your mobile Number"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="contact-service" className="form-label">Service</label>
                            <select
                                className="form-control"
                                id="contact-service"
                                name="service"
                                value={contactFormData.service}
                                onChange={handleContactChange}
                            >
                                <option value="">Select a Service</option>
                                <option value="Post Operative Care">Post Operative Care</option>
                                <option value="Physiotherapy">Physiotherapy</option>
                                <option value="Paralysis Care">Paralysis Care</option>
                                <option value="Pulmanory & Tracheostemy  Care">Pulmanory & Tracheostemy  Care</option>
                                <option value="Delivery Of Medicines">Delivery Of Medicines</option>
                                <option value="24 Hours Bedside Care">24 Hours Bedside Care</option>
                                <option value="Arranging Home Visits">Arranging Home Visits</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closecontactModal}>Close</button>
                            <button type="submit" className="btn btn-primary">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
            </div >

    {/* Confirmation Modal */ }
    < div class="modal fade show" id = "modal-11" tabindex = "-1" role = "dialog"  style = {{ display: confirmationModalVisible ? "block" : "none" }}>
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="icofont-close-line"></i>
                </button>

                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                </div>
                <div class="modal-body">
                    Thank you for your interest.
                    We will contact you soon.
                </div>
                <div class="modal-footer">
                    <div class="actions">
                        <button type="button" class="btn btn-secondary" onClick={closecontactModal}>Close</button>
                    </div>
                </div>
            </div>
        </div>
            </div >
        </div >
    );
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.AuthReducer.isAuthenticated
});

export default connect(mapStateToProps)(Home);
