const TYPE = {
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",
    VERIFY_SUCCESS: "VERIFY_SUCCESS",
    VERIFY_FAIL: "VERIFY_FAIL",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAIL: "GET_USER_FAIL",
    REFRESH_SUCCESS: "REFRESH_SUCCESS",
    REFRESH_FAIL: "REFRESH_FAIL",
    LOGOUT: "LOGOUT",
    CLOSE_ALERT: "CLOSE_ALERT",
    GUEST_VIEW: "GUEST_VIEW",
    CHECK_USER_ROLE_SUCCESS: "CHECK_USER_ROLE_SUCCESS",
    CHECK_USER_ROLE_FAIL: "CHECK_USER_ROLE_FAIL"
}

export default TYPE