import React , { useEffect } from 'react';
import Navbar from './Navbar';
import { connect } from "react-redux";
import { verify , getUser , googleLogin } from "../reducer/Actions";
import { useLocation } from 'react-router-dom';
import queryString from "query-string";

const Layout = (props) => {
    let location = useLocation();

    useEffect (() => {
        const values = queryString.parse(location.search);
        const code = values.code;
        console.log("code:" , code)
        if ( code ) {
            props.googleLogin( code );
        } else {
            props.verify();
            props.getUser();
        }
    }, [location.search]);

    const isLoginPage = location.pathname === '/login';
    
    const renderNavbar = !isLoginPage && <Navbar />;

    return (
        <div className='Layout'>
            {renderNavbar}
            {props.children}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {  
        message: state.AuthReducer.message,
        access: state.AuthReducer.access,
        refresh: state.AuthReducer.refresh,
        isAuthenticated: state.AuthReducer.isAuthenticated,
        user: state.AuthReducer.user
    }
}

export default connect(mapStateToProps, { verify, getUser, googleLogin })(Layout);
