import React from "react";
import Footer from './components/Footer';

const Appointments = () => {  

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <div class="card mb-0">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Photo</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Visit time</th>
                                            <th scope="col">Number</th>
                                            <th scope="col">Doctor</th>
                                            <th scope="col">Injury / Condition</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img src="../assets/content/user-40-10.jpg" alt="" width="40" height="40" class="rounded-500" />
                                            </td>
                                            <td>
                                                <strong>Liam</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-email p-0 me-2"></span>
                                                    liam@gmail.com
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">10 Feb 2018</div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">9:15 - 9:45</div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-cell-phone p-0 me-2"></span>
                                                    0126595743
                                                </div>
                                            </td>
                                            <td>Dr. Benjamin</td>
                                            <td>mumps</td>
                                            <td>
                                                <div class="actions">
                                                    <button class="btn btn-info btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-edit"></i></span>
                                                    </button>
                                                    <button class="btn btn-error btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../assets/content/user-40-10.jpg" alt="" width="40" height="40" class="rounded-500" />
                                            </td>
                                            <td>
                                                <strong>Emma</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-email p-0 me-2"></span>
                                                    emma@gmail.com
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">5 Dec 2018</div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">9:00 - 9:30</div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-cell-phone p-0 me-2"></span>
                                                    0126595743
                                                </div>
                                            </td>
                                            <td>Dr. Liam</td>
                                            <td>arthritis</td>
                                            <td>
                                                <div class="actions">
                                                    <button class="btn btn-info btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-edit"></i></span>
                                                    </button>
                                                    <button class="btn btn-error btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../assets/content/user-40-10.jpg" alt="" width="40" height="40" class="rounded-500" />
                                            </td>
                                            <td>
                                                <strong>Olivia</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-email p-0 me-2"></span>
                                                    olivia@gmail.com
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">13 Oct 2018</div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">12:00 - 12:45</div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-cell-phone p-0 me-2"></span>
                                                    0126595743
                                                </div>
                                            </td>
                                            <td>Dr. Noah</td>
                                            <td>depression</td>
                                            <td>
                                                <div class="actions">
                                                    <button class="btn btn-info btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-edit"></i></span>
                                                    </button>
                                                    <button class="btn btn-error btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../assets/content/user-40-10.jpg" alt="" width="40" height="40" class="rounded-500" />
                                            </td>
                                            <td>
                                                <strong>Ava</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-email p-0 me-2"></span>
                                                    ava@gmail.com
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">26 Dec 2018</div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">14:15 - 14:30</div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-cell-phone p-0 me-2"></span>
                                                    0126595743
                                                </div>
                                            </td>
                                            <td>Dr. Emma</td>
                                            <td>diarrhoea</td>
                                            <td>
                                                <div class="actions">
                                                    <button class="btn btn-info btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-edit"></i></span>
                                                    </button>
                                                    <button class="btn btn-error btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../assets/content/user-40-10.jpg" alt="" width="40" height="40" class="rounded-500" />
                                            </td>
                                            <td>
                                                <strong>Noah</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-email p-0 me-2"></span>
                                                    noah@gmail.co
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">15 Jun 2018</div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">17:30 - 18:00</div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-cell-phone p-0 me-2"></span>
                                                    0126595743
                                                </div>
                                            </td>
                                            <td>Dr. James</td>
                                            <td>dyslexia</td>
                                            <td>
                                                <div class="actions">
                                                    <button class="btn btn-info btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-edit"></i></span>
                                                    </button>
                                                    <button class="btn btn-error btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../assets/content/user-40-10.jpg" alt="" width="40" height="40" class="rounded-500" />
                                            </td>
                                            <td>
                                                <strong>Isabella</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-email p-0 me-2"></span>
                                                    isabella@gmail.com
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">2 Jul 2018</div>
                                            </td>
                                            <td>
                                                <div class="text-muted text-nowrap">10:00 - 10:15</div>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center nowrap text-primary">
                                                    <span class="icofont-ui-cell-phone p-0 me-2"></span>
                                                    0126595743
                                                </div>
                                            </td>
                                            <td>Dr. Noah</td>
                                            <td>flu</td>
                                            <td>
                                                <div class="actions">
                                                    <button class="btn btn-info btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-edit"></i></span>
                                                    </button>
                                                    <button class="btn btn-error btn-sm btn-square rounded-pill">
                                                        <span class="btn-icon"><i class="fas fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <nav class="mt-4">
                                <ul class="pagination">
                                    <li class="page-item disabled">
                                        <a class="page-link" href="#" aria-label="Previous" tabindex="-1" aria-disabled="true">
                                            <span class="btn-icon"><i class="fas fa-chevron-left"></i></span>
                                        </a>
                                    </li>
                                    <li class="page-item active" aria-current="page"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span class="btn-icon"><i class="fas fa-chevron-right"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div class="add-action-box">
                        <button class="btn btn-primary btn-lg btn-square rounded-pill" data-bs-toggle="modal" data-bs-target="#add-appointment">
                            <span><i class="fas fa-plus"></i></span>
                        </button>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Appointments;
