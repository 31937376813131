import React from "react";
import Footer from './components/Footer';

const EditPatient = () => {

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <div class="row">
                        <div class="col col-12 col-md-6 mb-4">
                            <form>
                                <label>Photo</label>
                                <div class="form-group avatar-box d-flex align-items-center">
                                    <img src="../assets/content/user-400-3.jpg" width="100" height="100" alt="" class="rounded-500 me-4"/>

                                        <button class="btn btn-outline-primary" type="button">
                                            Change photo<span class="btn-icon icofont-ui-user ms-2"></span>
                                        </button>
                                </div>

                                <div class="form-group">
                                    <label>Full name</label>
                                    <input class="form-control" type="text" placeholder="Full name" value="Liam Jouns"/>
                                </div>

                                <div class="form-group">
                                    <label>Id</label>
                                    <input class="form-control" type="text" placeholder="Id" value="10021" readonly/>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Age</label>
                                            <input class="form-control" type="number" placeholder="Age" value="25"/>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Gender</label>
                                            <select class="selectpicker" title="Gender">
                                                <option>Male</option>
                                                <option>Female</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Phone number</label>
                                    <input class="form-control" type="number" placeholder="Phone number" value="0126596578"/>
                                </div>

                                <div class="form-group">
                                    <label>Address</label>
                                    <textarea class="form-control" placeholder="Address" rows="3">71 Pilgrim Avenue Chevy Chase, MD 20815</textarea>
                                </div>

                                <div class="form-group">
                                    <label>Last visit</label>
                                    <input class="form-control" type="text" placeholder="Last visit" value="18 Dec 2019" readonly/>
                                </div>

                                <div class="form-group">
                                    <label>Status</label>
                                    <select class="selectpicker" title="Status">
                                        <option selected>Approved</option>
                                        <option>Pending</option>
                                    </select>
                                </div>

                                <button type="button" class="btn btn-success w-100">Save changes</button>
                            </form>
                        </div>

                        <div class="col col-12 col-md-6 mb-4">
                            <div class="v-timeline dots">
                                <div class="line"></div>

                                <div class="timeline-box">
                                    <div class="box-items">
                                        <div class="item">
                                            <div class="icon-block">
                                                <div class="item-icon bg-info"></div>
                                            </div>

                                            <div class="content-block">
                                                <div class="item-header">
                                                    <h3 class="h5 item-title">New prescription</h3>

                                                    <div class="item-date"><span>18 Dec 2019</span></div>
                                                </div>

                                                <div class="item-desc">Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut id elit.</div>
                                            </div>
                                        </div>

                                        <div class="item">
                                            <div class="icon-block">
                                                <div class="item-icon bg-danger"></div>
                                            </div>

                                            <div class="content-block">
                                                <div class="item-header">
                                                    <h3 class="h5 item-title">Appointment</h3>

                                                    <div class="item-date"><span>5 Oct 2019</span></div>
                                                </div>

                                                <div class="item-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate.</div>
                                            </div>
                                        </div>

                                        <div class="item">
                                            <div class="icon-block">
                                                <div class="item-icon bg-warning"></div>
                                            </div>

                                            <div class="content-block">
                                                <div class="item-header">
                                                    <h3 class="h5 item-title">Medication</h3>

                                                    <div class="item-date"><span>1 Oct 2019</span></div>
                                                </div>

                                                <div class="item-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.</div>
                                            </div>
                                        </div>

                                        <div class="item">
                                            <div class="icon-block">
                                                <div class="item-icon"></div>
                                            </div>

                                            <div class="content-block">
                                                <div class="item-header">
                                                    <h3 class="h5 item-title">Operation</h3>

                                                    <div class="item-date"><span>23 Sep 2019</span></div>
                                                </div>

                                                <div class="item-desc">Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut id elit.</div>
                                            </div>
                                        </div>

                                        <div class="item">
                                            <div class="icon-block">
                                                <div class="item-icon bg-dark"></div>
                                            </div>

                                            <div class="content-block">
                                                <div class="item-header">
                                                    <h3 class="h5 item-title">Examination</h3>

                                                    <div class="item-date"><span>10 Jul 2019</span></div>
                                                </div>

                                                <div class="item-desc">Lorem ipsum dolor sit.</div>
                                            </div>
                                        </div>

                                        <div class="item">
                                            <div class="icon-block">
                                                <div class="item-icon bg-success"></div>
                                            </div>

                                            <div class="content-block">
                                                <div class="item-header">
                                                    <h3 class="h5 item-title">Examination</h3>

                                                    <div class="item-date"><span>9 Jul 2019</span></div>
                                                </div>

                                                <div class="item-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.</div>
                                            </div>
                                        </div>

                                        <div class="item">
                                            <div class="icon-block">
                                                <div class="item-icon bg-warning"></div>
                                            </div>

                                            <div class="content-block">
                                                <div class="item-header">
                                                    <h3 class="h5 item-title">Medication</h3>

                                                    <div class="item-date"><span>30 Mar 2019</span></div>
                                                </div>

                                                <div class="item-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur nam nisi veniam.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default EditPatient;
