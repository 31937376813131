// 

import React, { useState } from "react";

function Signup() {
  const initialFormData = {
    email: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: ""
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password1) newErrors.password1 = "Password is required";
    if (formData.password1 !== formData.password2)
      newErrors.password2 = "Passwords do not match";
    return newErrors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("http://localhost:8000/dj-rest-auth/registration/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccess(true);
        setFormData(initialFormData);
        console.log("Signup successful:", data);
        new window.bootstrap.Modal(document.getElementById('modal-10')).show(); // Show the modal
      } else {
        const errorData = await response.json();
        setErrors({ submit: errorData.non_field_errors || "Signup failed" });
        console.error("Signup failed:", errorData);
      }
    } catch (error) {
      setErrors({ submit: error.message });
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };


  const handleRoleSubmit = async () => {
    try {
      const response = await fetch("http://127.0.0.1:8000/select_user_role/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role: role }),
      });

      if (response.ok) {
        console.log("Role submission successful");
        const modalInstance = new window.bootstrap.Modal(document.getElementById('modal-10'));
        modalInstance.hide(); // Hide the modal
      } else {
        const errorData = await response.json();
        setErrors({ role: errorData.non_field_errors || "Role submission failed" });
        console.error("Role submission failed:", errorData);
      }
    } catch (error) {
      setErrors({ role: error.message });
      console.error("Error:", error.message);
    }
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };


  return (
    <div className="public-layout" style={{ height: "100vh" }}>
      <div className="page-box">
        <div className="app-container page-sign-up">
          <div className="content-box">
            <div className="content-header">
              <div className="app-logo">
                <div className="logo-wrap">
                  {/* SVG logo here */}
                </div>
              </div>
            </div>
            <div className="content-body">
              <div className="w-100">
                <h2 className="h4 mt-0 mb-1">Sign up</h2>
                <p className="text-muted">Create your Account</p>
                <form onSubmit={onSubmit}>

                  <div className="form-group">
                    <input
                      className={`form-control ${errors.first_name ? "is-invalid" : ""}`}
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={onChange}
                    />
                    {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                  </div>

                  <div className="form-group">
                    <input
                      className={`form-control ${errors.last_name ? "is-invalid" : ""}`}
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={onChange}
                    />
                    {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                  </div>

                  <div className="form-group">
                    <input
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      type="email"
                      placeholder="Email address"
                      name="email"
                      value={formData.email}
                      onChange={onChange}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>

                  <div className="form-group">
                    <input
                      className={`form-control ${errors.password1 ? "is-invalid" : ""}`}
                      type="password"
                      placeholder="Password"
                      name="password1"
                      value={formData.password1}
                      onChange={onChange}
                    />
                    {errors.password1 && <div className="invalid-feedback">{errors.password1}</div>}
                  </div>

                  <div className="form-group">
                    <input
                      className={`form-control ${errors.password2 ? "is-invalid" : ""}`}
                      type="password"
                      placeholder="Confirm Password"
                      name="password2"
                      value={formData.password2}
                      onChange={onChange}
                    />
                    {errors.password2 && <div className="invalid-feedback">{errors.password2}</div>}
                  </div>

                  <div className="form-check form-switch mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="remember-me"
                    />
                    <label className="form-check-label" htmlFor="remember-me">
                      I agree to the Terms and Privacy
                    </label>
                  </div>

                  {errors.submit && <div className="alert alert-danger">{errors.submit}</div>}
                  {success && <div className="alert alert-success">Signup successful!</div>}

                  <div className="actions justify-content-between">
                    <button className="btn btn-primary" type="submit" disabled={loading}>
                      {loading ? "Signing up..." : "Sign up"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade" id="modal-10" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h5>Set Your Role</h5>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Role"
                  name="role"
                  value={role}
                  onChange={handleRoleChange} 
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="actions">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={handleRoleSubmit}>Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Signup;
