import React, { useState, useEffect } from "react";
import { DOCTOR_IMG1 } from "./assets/images/imagespath/imagepath";
import Footer from './components/Footer';

const Nurses = () => {
    const token = localStorage.getItem("auth_token");
    const [data, setData] = useState([]);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [nurseId, setNurseId] = useState(null);
    const [time, setTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [ModalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/get_nurses/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const handleSendRequest = () => {
        setLoading(true);

        const requestData = {
            user_name: userName,
            user_email: userEmail,
            user_mobile: userMobile,
            nurse_id: nurseId,
            time: new Date(time).toISOString()
        };

        fetch(`${process.env.REACT_APP_API_URL}/send_request_to_nurse/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(requestData)
        })
            .then((res) => res.json())
            .then((data) => {
                console.log("Request sent:", data);
                setUserName("");
                setUserEmail("");
                setUserMobile("");
                setTime("");
                setNurseId(null);
                setModalVisible(false);

            })
            .catch((err) => {
                console.log(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const openModal = (nurseId) => {
        setNurseId(nurseId);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleUserNameChange = (e) => setUserName(e.target.value);
    const handleUserEmailChange = (e) => setUserEmail(e.target.value);
    const handleUserMobileChange = (e) => setUserMobile(e.target.value);
    const handleTimeChange = (e) => setTime(e.target.value);

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className='main-content-wrap'>
                <div className='page-content'>
                    <div className="row">
                        {data.map((nurse, index) => (
                            <div className="col-12 col-md-4" key={index}>
                                <div className="contact">
                                    <div className="img-box">
                                        <img src={DOCTOR_IMG1} width="400" height="400" alt="" />
                                    </div>
                                    <div className="info-box">
                                        <h4 className="name">{nurse.first_name}</h4>
                                        <p className="role">{nurse.specialization}</p>
                                        <p className="address">{nurse.address}</p>
                                        <button className="btn btn-primary" onClick={() => openModal(nurse.id)}>Request Nurse</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Add Nurse Request Modal */}
                    <div className={`custom-modal ${ModalVisible ? 'show' : ''}`} id="contact-modal" tabIndex="-1" aria-hidden="true">
                        <div className="custom-modal-dialog">
                            <div className="custom-modal-content">
                                <div className="custom-modal-header">
                                    <h5 className="custom-modal-title">Send Nurse Request</h5>
                                    <button type="button" className="custom-close-button" onClick={closeModal}>×</button>
                                </div>
                                <div className="custom-modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="userName" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="userName"
                                            value={userName}
                                            onChange={handleUserNameChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="userEmail" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="userEmail"
                                            value={userEmail}
                                            onChange={handleUserEmailChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="userMobile" className="form-label">Mobile</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="userMobile"
                                            value={userMobile}
                                            onChange={handleUserMobileChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="time" className="form-label">Time</label>
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            id="time"
                                            value={time}
                                            onChange={handleTimeChange}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="actions">
                                        <button type="button" className="btn btn-secondary mr-2" onClick={closeModal}>Close</button>
                                        <button type="button" className="btn btn-primary ml-2" onClick={handleSendRequest} disabled={loading}>
                                            {loading ? "Sending..." : "Send Request"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Nurses;
