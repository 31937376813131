import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux"
import Home from "../screens/home/Home";
import Layout from "../components/Layout";
import Signup from "../screens/Signup";
import CareServices from "../CareServices";
import Doctors from "../Doctors"
import Appointments from "../Appointments";
import Patients from "../Patients";
import EditPatient from "../EditPatient";
import EditDoctor from "../EditDoctor";
import Nurses from "../Nurses";
import Store from "../Store";


const Main = () => {


    return (
        <Provider store={Store}>
            <Router>
                <div className="page-box">
                    <Layout />
                    <div className="app-container" style={{position : "fixed" , left : "0", right : "0" }}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/sign-up" component={Signup} />
                            <Route exact path="/services" component={CareServices} />
                            <Route exact path="/doctors" component={Doctors} />
                            <Route exact path="/nurses" component={Nurses} />
                            <Route exact path="/patients" component={Patients} />
                            <Route exact path="/edit-patient" component={EditPatient} />
                            <Route exact path="/edit-doctor" component={EditDoctor} />
                            <Route exact path="/appointments" component={Appointments} />
                        </Switch>
                    </div>
                </div>
            </Router>
        </Provider>
    );
};

export default Main;

