import React from 'react';
import { DEPARTMENT_IMG1, DEPARTMENT_IMG2, DEPARTMENT_IMG3 } from "./assets/images/imagespath/imagepath"
import Footer from './components/Footer';


const CareServices = () => {

    return (
        <div className='main-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
            <div className="main-content-wrap">
                <div className="page-content">
                <div className='mt-5'>
                        <div className="text-center">
                            <h4 className="mt-0 default_color">OUR SERVICES</h4>
                            <p className="mx-auto mb-5" style={{ width: "500px", fontSize: "15px" }}>
                                Our service portfolio is curated to meet all your expectations of home health care,
                                run by the best health care professionals and highly trained staff.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="card department bg-light bg-gradient mb-md-0">
                                    <img src={DEPARTMENT_IMG2} className="card-img-top" width="400" height="250" alt="" />
                                    <div className="card-body">
                                        <h3 className="h4 mt-0 Title">NURSING</h3>
                                        <p>
                                            Medical nurses specialised in critical care , attendants for short term nursing procedures
                                        </p>
                                        <div className="button-box pb-2">
                                            <button type="button" className="btn btn-outline-primary">
                                                See More<span className="btn-icon fas fa-ellipsis-vertical ms-2"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card department bg-light bg-gradient">
                                    <img src={DEPARTMENT_IMG1} className="card-img-top" width="400" height="250" alt="" />
                                    <div className="card-body">
                                        <h3 className="h4 mt-0 Title">ELDER CARE</h3>
                                        <p>
                                            Critical care to long term rehabiliation , we offer multi disciplinary service with a focus on geriatrics
                                        </p>
                                        <div className="button-box pb-2">
                                            <button type="button" className="btn btn-outline-primary">
                                                See More<span className="btn-icon fas fa-ellipsis-vertical ms-2"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="card department bg-light bg-gradient mb-md-0">
                                    <img src={DEPARTMENT_IMG3} className="card-img-top" width="400" height="250" alt="" />
                                    <div className="card-body">
                                        <h3 className="h4 mt-0 Title">MEDICAL EQUIPMENT</h3>
                                        <p>
                                            Buy or rent equipment for respiratory , cardiac, orthopaedic cases or mobility aids
                                        </p>
                                        <div className="button-box pb-2">
                                            <button type="button" className="btn btn-outline-primary">
                                                See More<span className="btn-icon fas fa-ellipsis-vertical ms-2"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-action-box">
                        <button className="btn btn-primary btn-lg btn-square rounded-pill" data-bs-toggle="modal" data-bs-target="#add-department">
                            <span className="btn-icon fa fa-plus"></span>
                        </button>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default CareServices;

